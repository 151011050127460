<template>
  <VSheet>
    <div class="print__wrapper" style="overflow:hidden">
      <div class="header">
        <VContainer>
          <VRow aling="center">
            <img src="@/assets/images/logo-dark.svg" alt="" />
          </VRow>
        </VContainer>
      </div>
      <VContainer>
        <VRow class="my-5 d-print-none ">
          <!-- <VBtn text color="primary" @click="onClose"
              ><VIcon left>mdi-arrow-left</VIcon>Назад</VBtn
            > -->
        </VRow>
        <VRow no-gutters justify="space-between" align="center">
          <div class="mt-1 d-print-none aling-center d-flex">
            <VBtn color="primary" @click="printPage">
              <VIcon left>mdi-printer</VIcon>
              {{ $t("btn.print") }}
            </VBtn>
          </div>
        </VRow>
        <VRow>
          <VCol cols="12">
            <ns-code-table
              :mainItem="mainItem"
              :isPrinting="isPrinting"
            />
          </VCol>
        </VRow>
      </VContainer>
    </div>
  </VSheet>
</template>

<script>
import notifications from "@/mixins/notifications";
import depotService from "../../services/request/depot/depotService";
import user from "../../mixins/user";
import NsCodeTable from "@/views/depots/components/nsCodeTable.vue";
import _ from "lodash";

export default {
  components: {NsCodeTable},
  mixins: [notifications, user],
  data: () => ({
    integrateDialog: false,
    mainItem: [],
    addSupplyOutDialog: false,
    addSupplyItem: "",
    viewProductDialog: false,
    viewProduct: null,
    itemCapacity: "",
    isPrinting: true
  }),
  methods: {
    async printPage() {
      window.print();
    },
    getCapacity() {
      this.itemCapacity =
        (this?.mainItem.size_height * this?.mainItem.size_length * this?.mainItem.size_width) /
        1000000000;
    },
    onBack() {
      // if (this.getUser.type === 'client') {
      //   this.$router.push({name: 'products'});
      // } else {
      this.$router.push({ name: "products" }).catch(() => {});
      // }
    },
    getItemDetailsProp(prop) {
      return _.get(this.mainItem, `${prop}`);
    },


  },
  async mounted() {
    try {
      this.mainItem = await depotService.getProduct(this.$route.params.barCode);
      // this.options.children.name = this.mainItem.options_details.name
      // this.options.children.amount = this.mainItem.options_details.count
      if (!this.mainItem) {
        // eslint-disable-next-line
        throw "Item Not Found";
      }

      this.getCapacity();

    } catch (e) {
      this.setLoading(false);
      console.log(e)
    }
  },
  beforeDestroy() {
    this.isPrinting = false;
  },
  computed: {
    productsHeaders() {
      return [{value: "column1"}, {value: "column2"}];
    },
    productsItems() {
      return [
        {
          column1: this.$t("table.name"),
          column2: this.getItemDetailsProp("name") || "--"
        },
      ];
    },
    placingHeaders() {
      return [{value: "column1"}, {value: "column2"}];
    },
    placingItems() {
      return [
        {
          column1: this.$t("products.dimensions"),
          column2: this?.capacity ? this.capacity : "--"
        },
        {
          column1: this.$t("products.capacity"),
          column2: this?.itemCapacity ? this.itemCapacity : "--"
        },
      ];
    },
    capacity(){
      return this.mainItem.size_height + 'x' + this.mainItem.size_length  + 'x' +  this.mainItem.size_width
    }
  }
};
</script>

<style lang="scss">
body,
html {
  overflow: unset !important;
}
.header {
  width: 100%;
  background: darken(white, 5);
  border-bottom: 1px solid darken(white, 10);
  padding: 10px 0;
  height: auto;
}
.print__wrapper {
  background: #ffff;
}

@media print {
  .my-table {
    .v-data-table__wrapper {
      overflow-x: hidden !important;
    }
  }
  .container {
    padding: 10px 10px !important;
  }
  .header {
    padding: 10px;
  }
}
.fade-print-leave-active {
  transition: opacity 0.5s;
}
.fade-print-leave-to {
  opacity: 0;
}
.loader__wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 80px;
  z-index: 100;
  background: white;
  height: calc(100vh - 397px);
  width: 100%;
  left: 0;
  top: 397px;
}
</style>
