<template>
  <bar-code-list-print-dialog />
</template>

<script>
import BarCodeListPrintDialog from '@/components/dialog/BarCodeListPrintDialog.vue';

export default {
  name: 'BarCode',
  components: {
    BarCodeListPrintDialog,
  },
};
</script>

<style scoped>

</style>
